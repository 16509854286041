.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    justify-content: space-between;
}

#sidebar {
    min-width: 200px;
    max-width: 215px;
    transition: all 0.3s;
    background-color: #fff;
    backdrop-filter: opacity(0.5);


    background-position: center;
    background-size: cover;
    /* border-right: 1px solid #6c757d; */
    transition: .5s;
    position: relative;
    animation-name: hide;
    animation-duration: .5s;
    border-right: 1px solid #e7e9ed;
}



#minisidebar {
    min-width: 90px;
    max-width: 90px;
    transition: all 0.3s;
}

#sidebar.hide {
    /* display: none; */

    width: 0;
    min-width: 0;
    left: -100%;

}

#sidebar.show {
    /* display: none; */
    width: 200px;
    min-width: 200px;
    max-width: 215px;
    left: 0;
    animation-duration: .1s;

}

#minisidebar.active {
    display: none;
}

#sidebar .sidebar-header,
#minisidebar .sidebar-header {
    padding: 20px;
    text-align: center;

}




#sidebar ul li a,
#minisidebar ul li a {
    padding: 10px;
    font-size: 1em;
    display: block;
    text-decoration: none;
    padding-left: 1rem;
    color: #fff;
}

#sidebar ul li a:hover,
#minisidebar ul li a:hover {
    color: #fff;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
    color: #fff;
    background-color: #3465ceab;
    border-top: none !important;
}

a[data-toggle="collapse"] {
    position: relative;
}

.dropdown-toggle::after {
    display: block;
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}


ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}



/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
    width: 100%;
    min-height: 100vh;
    transition: all 0.3s;
}

/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {

    /* #sidebar {
        margin-left: -215px;
    } */
    #sidebar.active {
        display: block;
        margin-left: 0;
    }

    #sidebarCollapse span {
        display: none;
    }
}

.minisidebar-header {
    width: 91px;
}

.custom-link-sidebar {
    padding: 10px;
    font-size: 1em;
    display: block;
    padding-left: 1rem;
    color: #252930;
    cursor: pointer;
}

.active>.custom-link-sidebar {
    color: var(--primary-color);
    background-color: var(--highlight-color);
    border-top: none !important;
    border-left: 3px solid var(--primary-color);
}

#sidebar .fa,
#sidebar .fa-solid {
    color: var(--primary-color) !important
}

.img-container{
    border: 2px solid var(--primary-color) !important;
}
.menu-list li:first-child>span {
    border-top: 1px solid var(--primary-color);
}